import { render, staticRenderFns } from "./DetailShare.vue?vue&type=template&id=0ad6e08e&scoped=true&"
import script from "./DetailShare.vue?vue&type=script&lang=js&"
export * from "./DetailShare.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ad6e08e",
  null
  
)

export default component.exports